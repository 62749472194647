import React from "react"
import { IFilters } from "../../../types/interfaces/IFilters"
import { ContractStatus } from "../../../types/enums/contract-status"
import { getResourceStringByid } from "../../../utils/resource-string-helper"
import { useAllKontentResourceStringData } from "../../../graphql-static/use-resource-strings"

const updateStatusFilter = (
  selectedStatus: string,
  currentStatuses: string[]
) => {
  if (selectedStatus === ContractStatus.ALL) {
    return {
      status: [
        ContractStatus.ALL,
        ContractStatus.NOT_STARTED,
        ContractStatus.IN_PROGRESS,
        ContractStatus.BOOKED,
        ContractStatus.XHQUOTED,
      ],
    }
  } else if (currentStatuses?.includes(selectedStatus)) {
    const updatedStatus = currentStatuses?.filter(
      status => status !== selectedStatus && status !== ContractStatus.ALL
    )
    return {
      status: updatedStatus,
    }
  } else if (
    !currentStatuses.includes(ContractStatus.ALL) &&
    currentStatuses.length === 3
  ) {
    return {
      status: [...currentStatuses, selectedStatus, ContractStatus.ALL],
    }
  } else {
    return {
      status: [...currentStatuses, selectedStatus],
    }
  }
}

export interface IAdvancedFilteringPillsProps {
  activeFilters: IFilters
  updateFilterState: (state: any) => void
}

export const AdvancedFileringPills = (props: IAdvancedFilteringPillsProps) => {
  const resourceStrings = useAllKontentResourceStringData()
  const currentStatuses = props.activeFilters?.status || []

  return (
    <div className="flex items-center justify-between px-4 py-3 border-b sm:px-6 lg:px-8">
      <p className="font-bold uppercase">
        {getResourceStringByid(
          resourceStrings,
          "908fdcf9-c156-5685-af7f-55cc28e014fa"
        )}
      </p>
      <div className="flex space-x-2">
        <button
          type="button"
          onClick={() =>
            props.updateFilterState(
              updateStatusFilter(ContractStatus.ALL, currentStatuses)
            )
          }
          className={`${
            currentStatuses?.includes(ContractStatus.ALL)
              ? "bg-filteringPillsHover text-white"
              : "text-black"
          } normal-case px-5 py-2.5 border text-xs 2xl:text-sm 4xl:text-md font-medium rounded-full hover:bg-filteringPillsHover hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          {ContractStatus.ALL}
        </button>
        <button
          type="button"
          onClick={() =>
            props.updateFilterState(
              updateStatusFilter(ContractStatus.XHQUOTED, currentStatuses)
            )
          }
          className={`${
            currentStatuses?.includes(ContractStatus.XHQUOTED)
              ? "bg-filteringPillsHover text-white"
              : "text-black"
          } normal-case px-5 py-2.5 border text-xs 2xl:text-sm 4xl:text-md font-medium rounded-full hover:bg-filteringPillsHover hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          {ContractStatus.XHQUOTED}
        </button>
        <button
          type="button"
          onClick={() =>
            props.updateFilterState(
              updateStatusFilter(ContractStatus.BOOKED, currentStatuses)
            )
          }
          className={`${
            currentStatuses?.includes(ContractStatus.BOOKED)
              ? "bg-filteringPillsHover text-white"
              : "text-black"
          } normal-case px-5 py-2.5 border text-xs 2xl:text-sm 4xl:text-md font-medium rounded-full hover:bg-filteringPillsHover hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          {ContractStatus.BOOKED}
        </button>
        <button
          type="button"
          onClick={() =>
            props.updateFilterState(
              updateStatusFilter(ContractStatus.IN_PROGRESS, currentStatuses)
            )
          }
          className={`${
            currentStatuses?.includes(ContractStatus.IN_PROGRESS)
              ? "bg-filteringPillsHover text-white"
              : "text-black"
          } normal-case px-5 py-2.5 border text-xs 2xl:text-sm 4xl:text-md font-medium rounded-full hover:bg-filteringPillsHover hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          {ContractStatus.IN_PROGRESS}
        </button>
        <button
          type="button"
          onClick={() =>
            props.updateFilterState(
              updateStatusFilter(ContractStatus.NOT_STARTED, currentStatuses)
            )
          }
          className={`${
            currentStatuses?.includes(ContractStatus.NOT_STARTED)
              ? "bg-filteringPillsHover text-white"
              : "text-black"
          } normal-case px-5 py-2.5 border text-xs 2xl:text-sm 4xl:text-md font-medium rounded-full hover:bg-filteringPillsHover hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          {ContractStatus.NOT_STARTED}
        </button>
      </div>
    </div>
  )
}
