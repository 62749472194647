import React from "react"
import { IContract } from "../../types/interfaces/IGetAllContractsResponse"
import moment from "moment";

interface IContractFlagsProps {
    contract: IContract
    handleNavigateToContractPage: (contractId: number) => void
  }

export const ContractFlags = (props: IContractFlagsProps) => {

    const contractLink = (
        <a
            href="#"
            onClick={(e) => {
                e.preventDefault()
                props.handleNavigateToContractPage(props.contract.contractId)
            }}
        >
            {props.contract.contractNo}
        </a>
    )

    if (props.contract.urgentActionRequired !== true && props.contract.isQuote === true && props.contract.isQuoteReady !== true) {
        let statusColour: string = ""
        let statusText: string = ""
        let statusWarningClass = ""
        if (props.contract.quoteRequiredBy) {
            const requiredByDateTime: any = moment(props.contract.quoteRequiredBy)
            if (requiredByDateTime.isValid()) {
              const now: any = new Date()
              const diffTime = now - requiredByDateTime
              const diffMinutes = Math.ceil(diffTime / (1000 * 60))
              const isLate = diffMinutes >= 0
              statusColour = isLate ? "#e4181a" : "#ffa300"
              statusText = 
                `HireHub quotation for contract ${props.contract.contractNo} is ${isLate ? "late (was": "outstanding (is"} required by ${requiredByDateTime.format("HH:mm")} on ${requiredByDateTime.format("DD/MM/YYYY")})`

              if (diffMinutes >= -5 && diffMinutes < 0) {
                statusText += " - DUE SOON"
                statusWarningClass = "animate-ping"
              }
            }

            return (
                <>
                    <div className="mr-2" title={statusText}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className={statusWarningClass}>
                            <g stroke={statusColour} strokeWidth="2.23">
                                <circle fill="#ffffff" cx="10" cy="10" r="8.5652" />
                                <path d="M10,4.188V11.657M10,13.438V15.813" />
                            </g>
                        </svg>
                    </div>
                    {contractLink}
                </>
            )
        }
    }

    if (props.contract.urgentActionRequired === true) {
        return (
            <>
                <div className="mr-2" title={`Ugent Action required on Contract No: ${props.contract.contractNo}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" id="ICON_Flag" width="20" height="20" viewBox="0 0 20 20">
                        {" "}
                        <g id="Rectangle_6099" data-name="Rectangle 6099" fill="#e4181a" stroke="#e4181a" strokeWidth="1">
                            {" "}
                            <rect width="20" height="20" rx="10" stroke="none" />
                            {" "}
                            <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="none" />
                            {" "}
                        </g>
                        {" "}
                        <g id="Icon_feather-flag" data-name="Icon feather-flag" transform="translate(0.039 2.051)">
                            {" "}
                            <path id="Path_69483" data-name="Path 69483" d="M6,9.757a2.8,2.8,0,0,1,1.98-.52c1.485,0,2.475,1.039,3.96,1.039a2.8,2.8,0,0,0,1.98-.52V3.52a2.8,2.8,0,0,1-1.98.52C10.456,4.039,9.465,3,7.98,3A2.8,2.8,0,0,0,6,3.52Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                            {" "}
                            <path id="Path_69484" data-name="Path 69484" d="M6,25.562V22.5" transform="translate(0 -12.661)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                            {" "}
                        </g>
                    </svg>
                </div>
                {contractLink}
            </>
        )
    }

    return (
        <>
            <div className="invisible w-5 mr-2">
                <p className="sr-only">
                    {props.contract.contractNo}
                </p>
            </div>
            {contractLink}
        </>
    )
}