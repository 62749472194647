import moment from "moment"
import { ContractStatus } from "../types/enums/contract-status"
import { IFilters } from "../types/interfaces/IFilters"
import { IContract } from "../types/interfaces/IGetAllContractsResponse"

export const applyPillFiltering = (
  contracts: IContract[],
  activeFilters: IFilters
): IContract[] => {

  if (activeFilters?.status?.includes(ContractStatus.ALL)) {
    return contracts
  } else {
    const filteredContracts = contracts.filter(contract => activeFilters.status?.includes(contract.status))
    return filteredContracts
  }
}

export const applyAdvancedFiltering = (
  contracts: IContract[],
  activeFilters: IFilters
): IContract[] => {
  if (contracts.length && activeFilters.contractNo)
    contracts = contracts.filter(
      contract => contract.contractNo === activeFilters.contractNo
    )

  if (contracts.length && activeFilters.equipmentCategory)
    contracts = contracts.filter(
      contract =>
        contract.equipmentCategory != null &&
        contract.equipmentCategory
          .split(",")
          .map(item => item.trim())
          .includes(activeFilters.equipmentCategory)
    )

  if (contracts.length && activeFilters.siteAddress)
    contracts = contracts.filter(
      contract => contract.siteAddress === activeFilters.siteAddress
    )

  if (contracts.length && activeFilters.location)
    contracts = contracts.filter(
      contract => contract.siteCounty === activeFilters.location || contract.siteTown === activeFilters.location
    )

  if (contracts.length && activeFilters.hireStartDateFromNow)
    contracts = contracts.filter(
      contract =>
        contract.hireStartDateFromNow === activeFilters.hireStartDateFromNow
    )

  if (contracts.length && activeFilters.customerName)
    contracts = contracts.filter(
      contract => contract.companyName === activeFilters.customerName
    )

  if (contracts.length && activeFilters.hireDuration)
    contracts = contracts.filter(contract => {
      return (
        contract.hireDuration !== null &&
        contract.hireDuration.toString() === activeFilters.hireDuration
      )
    })

  if (contracts.length && activeFilters.assignedUser)
    contracts = contracts.filter(
      contract => contract.assignment === activeFilters.assignedUser
    )

  if (
    contracts.length &&
    activeFilters.requiredDateFrom &&
    activeFilters.requiredDateTo
  ) {
    contracts = contracts.filter(contract => {
      const hireStartDateFormatted = moment(contract.hireStartDate).format(
        "YYYY-MM-DD"
      )
      return (
        hireStartDateFormatted >= activeFilters.requiredDateFrom &&
        hireStartDateFormatted <= activeFilters.requiredDateTo
      )
    })
  } else if (contracts.length && activeFilters.requiredDateFrom) {
    contracts = contracts.filter(contract => {
      const hireStartDateFormatted = moment(contract.hireStartDate).format(
        "YYYY-MM-DD"
      )
      return hireStartDateFormatted === activeFilters.requiredDateFrom
    })
  }

  if (contracts.length && activeFilters.isQuote && activeFilters.isQuote !== "") {
    contracts = contracts.filter(
      contract => contract.isQuote === (activeFilters.isQuote === 'Y' ? true : false)
    )
  }

  return contracts
}
