import React, { useEffect, useState } from "react"
import { useMsal } from "@azure/msal-react"
import {
  getAllAssignedUsers,
  getAllContracts,
} from "../../middleware/middleware-layer"
import { IGetAllContractsResponse } from "../../types/interfaces/IGetAllContractsResponse"
import moment from "moment"
import { Itenant } from "../../types/interfaces/IGetAllTenantsResponse"
import { useAllKontentResourceStringData } from "../../graphql-static/use-resource-strings"
import { getResourceStringByid } from "../../utils/resource-string-helper"

const globalBaseContractInformation = {
  internalGetAllContracts: {
    contracts: [],
    totals: {
      sameDay: {
        quotes: {
          name: "",
          total: 0,
          locations: []
        },
        other: {
          name: "",
          total: 0,
          locations: []
        },
        total: 0
      },
      nextDay: {
        quotes: {
          name: "",
          total: 0,
          locations: []
        },
        other: {
          name: "",
          total: 0,
          locations: []
        },
        total: 0
      },
      future: {
        quotes: {
          name: "",
          total: 0,
          locations: []
        },
        other: {
          name: "",
          total: 0,
          locations: []
        },
        total: 0
      },
    },
  },
}

const globalAssingnedUsers = [
  {
    userId: 0,
    displayName: "",
  },
]

interface IDataRefreshAndTimestampProps {
  callback: (data: IGetAllContractsResponse, users: Itenant[]) => void
  interval: number
}

export const DataRefreshAndTimestamp = (
  props: IDataRefreshAndTimestampProps
) => {
  const resourceStrings = useAllKontentResourceStringData()

  const { accounts, instance } = useMsal()

  const [timestamp, setTimestamp] = useState<string>("")

  const getTimeStamp = (): string => {
    try {
      const momentTimeStamp = moment()
      return (
        momentTimeStamp.format("DD-MM-YYYY") +
        " at " +
        momentTimeStamp.format("h:mm a")
      )
    } catch (error: any) {
      console.error(error)
      return ""
    }
  }

  const fetchData = () => {
    const promises = [
      getAllContracts(accounts, instance),
      getAllAssignedUsers(accounts, instance),
    ]
    Promise.allSettled(promises).then((results: any) => {
      props.callback(results[0].value.data, results[1].value.data)
      setTimestamp(getTimeStamp())
    })
  }

  // Base API call - fetches data on first render
  useEffect(() => {
    try {
      if (accounts.length > 0) {
        fetchData()
      } else {
        props.callback(globalBaseContractInformation, globalAssingnedUsers)
      }
    } catch (error: any) {
      console.error(error)
    }
  }, [accounts, instance])

  //Re-fresh data every x milliseconds
  useEffect(() => {
    try {
      const interval = setInterval(async () => {
        if (accounts.length > 0) {
          fetchData()
        }
      }, props.interval)

      return () => {
        clearInterval(interval)
      }
    } catch (error: any) {
      console.error(error)
    }
  }, [accounts, instance, props.interval])

  return (
    <span>
      (
      {getResourceStringByid(
        resourceStrings,
        "21fc7e30-a2f4-52b6-961a-060db1729b61"
      )}{" "}
      {timestamp})
    </span>
  )
}
