import * as React from "react"
import Moment from "react-moment"
import { ContractStatusEditor } from "../contract-status-edit"
import { IContract } from "../../types/interfaces/IGetAllContractsResponse"
import { useEffect, useState } from "react"
import _ from "lodash"
import { ContractEditUser } from "../contract-edit-user"
import { Itenant } from "../../types/interfaces/IGetAllTenantsResponse"
import { ContractStatus } from "../../types/enums/contract-status"
import { IFilters } from "../../types/interfaces/IFilters"
import { useAllKontentResourceStringData } from "../../graphql-static/use-resource-strings"
import { getResourceStringByid } from "../../utils/resource-string-helper"
import moment from "moment"
import { ContractFlags } from "../contract-flags"

export type tableListerProps = {
  contracts: IContract[]
  tenants: Itenant[]
  activeFilters: IFilters
  updateContractStatusCallback: (
    status: ContractStatus,
    contractId: number
  ) => void
  updateContractTenantCallback: (tenant: string, contractId: number) => void
  handleNavigateToContractPage: (contractId: number) => void
}

export const TableLister = (props: tableListerProps) => {
  const resourceStrings = useAllKontentResourceStringData()

  const [renderAmount, setRenderAmount] = useState<number>(11)

  const [activeSort, setActiveSort] = useState<any>({
    name: "",
    type: "",
  })

  const sort = (name: string) => {
    let sortDirection = "desc"
    if (activeSort.name === name && activeSort.type === sortDirection) {
      sortDirection = "asc"
    }
    setActiveSort({ name: name, type: sortDirection })
  }

  const toggleSortIcon = (toggle: string) => {
    if (toggle !== activeSort.name) return

    if (activeSort.type === "desc") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      )
    } else {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 15l7-7 7 7"
          />
        </svg>
      )
    }
  }

  const sortDataBeforeRender = (): IContract[] => {
    return _.orderBy(props.contracts, [activeSort.name], [activeSort.type])
  }

  useEffect(() => {
    setRenderAmount(11)
  }, [props.activeFilters])

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="inline-block w-full align-middle">
          <div className="border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-300">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-black-900 text-bold"
                  >
                    <button
                      className="flex items-center"
                      onClick={() => sort("contractNo")}
                    >
                      {getResourceStringByid(
                        resourceStrings,
                        "31c53ebe-c9cf-5bd7-ba34-ef4a587ce238"
                      )}
                      {activeSort.name !== "contractNo" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                      {toggleSortIcon("contractNo")}
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-black-900 text-bold"
                  >
                    <button
                      className="flex items-center"
                      onClick={() => sort("hireStartDate")}
                    >
                      {getResourceStringByid(
                        resourceStrings,
                        "eb40eb30-f5cc-55fd-bbb8-86e17e2e6c4a"
                      )}
                      {activeSort.name !== "hireStartDate" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                      {toggleSortIcon("hireStartDate")}
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-black-900 text-bold"
                  >
                    <button
                      className="flex items-center"
                      onClick={() => sort("equipmentCategory")}
                    >
                      {getResourceStringByid(
                        resourceStrings,
                        "4b9dacce-8b2c-5807-9ecd-60fdc89cd711"
                      )}
                      {activeSort.name !== "equipmentCategory" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                      {toggleSortIcon("equipmentCategory")}
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-black-900 text-bold"
                  >
                    <button
                      className="flex items-center"
                      onClick={() => sort("siteAddress")}
                    >
                      {getResourceStringByid(
                        resourceStrings,
                        "82555a48-08ed-5f05-9fb7-4cdbd5399d39"
                      )}
                      {activeSort.name !== "siteAddress" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                      {toggleSortIcon("siteAddress")}
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-black-900 text-bold"
                  >
                    <button
                      className="flex items-center"
                      onClick={() => sort("companyName")}
                    >
                      {getResourceStringByid(
                        resourceStrings,
                        "fd47ed1a-fbf2-5f6a-b891-184a6bbaf568"
                      )}
                      {activeSort.name !== "companyName" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                      {toggleSortIcon("companyName")}
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-black-900 text-bold"
                  >
                    <button
                      className="flex items-center"
                      onClick={() => sort("hireDuration")}
                    >
                      {getResourceStringByid(
                        resourceStrings,
                        "7f2cd516-3950-5d02-ba8c-962f2e31825e"
                      )}
                      {activeSort.name !== "hireDuration" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                      {toggleSortIcon("hireDuration")}
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-black-900 text-bold"
                  >
                    <button
                      className="flex items-center"
                      onClick={() => sort("isQuote")}
                    >
                      Quote
                      {activeSort.name !== "isQuote" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                      {toggleSortIcon("isQuote")}
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-black-900 text-bold"
                  >
                    <button
                      className="flex items-center"
                      onClick={() => sort("assignment")}
                    >
                      {getResourceStringByid(
                        resourceStrings,
                        "13d09b3d-2de2-52b5-8c90-da970ae64ad5"
                      )}
                      {activeSort.name !== "assignment" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                      {toggleSortIcon("assignment")}
                    </button>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-black-900 text-bold"
                  >
                    <button
                      className="flex items-center"
                      onClick={() => sort("status")}
                    >
                      {getResourceStringByid(
                        resourceStrings,
                        "086c3a9d-2730-5acf-a151-74e68cbd2155"
                      )}
                      {activeSort.name !== "status" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-4 h-4 ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                      {toggleSortIcon("status")}
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortDataBeforeRender()
                  .slice(
                    0,
                    renderAmount > props.contracts.length
                      ? props.contracts.length
                      : renderAmount
                  )
                  .map((contract, personIdx) => (
                    <tr
                      key={personIdx}
                      className="even:bg-gray-100 odd:bg-white"
                    >
                      <td className="align-middle px-4 py-4 text-sm whitespace-nowrap text-black-900">
                        <div className="flex flex-row">
                          <ContractFlags 
                            contract={contract} 
                            handleNavigateToContractPage={props.handleNavigateToContractPage}
                          />
                        </div>
                      </td>
                      <td className="px-4 py-4 text-sm whitespace-nowrap">
                        {moment(contract.deliveryDate).isValid() && (
                          <Moment format="DD-MM-YYYY">
                            {contract.deliveryDate}
                          </Moment>
                        )}
                      </td>
                      <td className="px-4 py-4 text-sm">
                        {contract.equipmentCategory}
                      </td>
                      <td className="px-6 py-4 text-sm">
                        {contract.siteAddress}
                      </td>
                      <td className="px-4 py-4 text-sm whitespace-nowrap">
                        {contract.companyName}
                      </td>
                      <td className="px-4 py-4 text-sm whitespace-nowrap text-center">
                        {contract.hireDuration !== null
                          ? contract.hireDuration === 1
                            ? `${contract.hireDuration} Day`
                            : `${contract.hireDuration} Days`
                          : "Open hire"}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap text-center">
                        {contract.isQuote 
                          ? contract.isProspectQuote ? <span className="text-sm">Prospect</span> : <>&#10003;</> 
                          : ""}
                      </td>
                      <td className="px-4 py-4 text-sm whitespace-nowrap">
                        <ContractEditUser
                          updateContractTenantCallback={(tenant, contractId) =>
                            props.updateContractTenantCallback(
                              tenant,
                              contractId
                            )
                          }
                          contract={contract}
                          tenants={props.tenants}
                        />
                      </td>
                      <td className="px-4 py-4 text-sm whitespace-nowrap text-black-900">
                        <div className="p-2 -mt-2 break-words transition-all rounded-md hover:bg-gray-200">
                          <ContractStatusEditor
                            updateContractStatusCallback={(
                              status,
                              contractId,
                            ) =>
                              props.updateContractStatusCallback(
                                status,
                                contractId
                              )
                            }
                            contractId={contract.contractNo}
                            isQuote={contract.isQuote}
                            status={contract.status}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-center py-14">
          {renderAmount < props.contracts.length && (
            <button
              className="uppercase px-24 py-2.5 border-2 text-m 2xl:text-sm 4xl:text-md font-medium rounded-full hover:bg-filteringPillsHover hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 text-black"
              onClick={() => setRenderAmount(renderAmount + 11)}
            >
              LOAD MORE
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
