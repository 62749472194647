import React from "react"
import { useAllKontentResourceStringData } from "../../graphql-static/use-resource-strings"
import { ITotalType } from "../../types/interfaces/IGetAllContractsResponse"
import { getResourceStringByid } from "../../utils/resource-string-helper"
import { CardTotal } from "../card-totals"

export type cardListerProps = {
  sameDay: ITotalType
  nextDay: ITotalType
  future: ITotalType
  updateFilterState: (state: any) => void
}

export const CardLister = (props: cardListerProps) => {
  const resourceStrings = useAllKontentResourceStringData()

  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-6 my-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-12 flex flex-row"
    >
      <CardTotal
        updateFilterState={props.updateFilterState}
        totals={props.sameDay}
        title={getResourceStringByid(
          resourceStrings,
          "374269fa-a6da-5401-99c9-78ff0ec9d2a5"
        )}
      />
      <CardTotal
        updateFilterState={props.updateFilterState}
        totals={props.nextDay}
        title={getResourceStringByid(
          resourceStrings,
          "1c0586e4-86e4-554b-a4bf-6411611c8851"
        )}
      />
      <CardTotal
        updateFilterState={props.updateFilterState}
        totals={props.future}
        title={getResourceStringByid(
          resourceStrings,
          "75c74404-dea2-5d2b-8733-678e0866d9e8"
        )}
      />
    </ul>
  )
}

export default CardLister
