import React, { useEffect, useState } from "react"
import Select from "react-select"
import DatePicker from "react-datepicker"
import { IFilters } from "../../../types/interfaces/IFilters"
import baseFilters from "../../../mocks/baseFilters.json"
import moment from "moment"
import _ from "lodash"
import { useAllKontentResourceStringData } from "../../../graphql-static/use-resource-strings"
import { getResourceStringByid } from "../../../utils/resource-string-helper"

export type advancedFilteringProps = {
  activeFilters: IFilters
  equipmentCategories: ISelectOptions[]
  addresses: ISelectOptions[]
  locations: ISelectOptions[]
  customerNames: ISelectOptions[]
  assignedUsers: ISelectOptions[]
  status: ISelectOptions[]
  updateFilterState: (state: any) => void
}

interface ISelectOptions {
  label: string
  value: string
}

export const AdvancedFilteringOptions = (props: advancedFilteringProps) => {
  const resourceStrings = useAllKontentResourceStringData()

  const [advancedFilterOptions, setAdvancedFilterOptions] =
    useState<IFilters>(props.activeFilters || baseFilters)

  const onChangeUpdateState = (state: Partial<IFilters>) => {
    setAdvancedFilterOptions({ ...advancedFilterOptions, ...state, status: props.activeFilters.status })
  }

  const convertDataToSelectType = (data: string): ISelectOptions => {
    return {
      value: data,
      label: data,
    }
  }

  const contractTypes: ISelectOptions[] = [
    { value: "N", label: "Firm Bookings Only" },
    { value: "Y", label: "Quotes Only" },
  ]

  const convertTypeDataToSelectType = (data: string): ISelectOptions => {
    const contractType = contractTypes.find(contractType => {
        return contractType.value === data
    })
    return contractType || contractTypes[0]
  }

  const search = () => {
    props.updateFilterState({ ...advancedFilterOptions, status: props.activeFilters.status })
  }

  const locationOnlyFilter = {
    ...baseFilters,
    ...{ location: props.activeFilters.location },
  }

  useEffect(() => {
    // when the advanced filters have options selected and a filter in the card is clicked,
    //they all need to be cleared. with the exception of the location prop.

    if (props.activeFilters.location !== "" && 
      JSON.stringify(locationOnlyFilter) !== JSON.stringify(advancedFilterOptions)
    ) {
      onChangeUpdateState(locationOnlyFilter)
    }
  }, [props.activeFilters.location])

  return (
    <form className="flex flex-col w-full">
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <input
          type="number"
          value={
            advancedFilterOptions.contractNo === 0
              ? ""
              : advancedFilterOptions.contractNo
          }
          placeholder="Contract No."
          className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          onChange={e => {
            onChangeUpdateState({ contractNo: Number(e.target.value) })
          }}
        />
        <Select
          value={
            advancedFilterOptions.equipmentCategory === ""
              ? null
              : convertDataToSelectType(advancedFilterOptions.equipmentCategory)
          }
          escapeClearsValue={true}
          backspaceRemovesValue={true}
          options={props.equipmentCategories}
          placeholder={" Equipment Category"}
          onChange={(option: any) =>
            onChangeUpdateState({ equipmentCategory: option.value })
          }
        />
        <Select
          value={
            advancedFilterOptions.siteAddress === ""
              ? null
              : convertDataToSelectType(advancedFilterOptions.siteAddress)
          }
          escapeClearsValue={true}
          backspaceRemovesValue={true}
          options={props.addresses}
          placeholder={"Site Address"}
          onChange={(option: any) =>
            onChangeUpdateState({ siteAddress: option.value })
          }
        />
        <Select
          value={
            advancedFilterOptions.location === ""
              ? null
              : convertDataToSelectType(advancedFilterOptions.location)
          }
          escapeClearsValue={true}
          backspaceRemovesValue={true}
          options={props.locations}
          placeholder={"Location"}
          onChange={(option: any) =>
            onChangeUpdateState({ location: option.value })
          }
        />
        <Select
          value={
            advancedFilterOptions.customerName === ""
              ? null
              : convertDataToSelectType(advancedFilterOptions.customerName)
          }
          escapeClearsValue={true}
          backspaceRemovesValue={true}
          options={props.customerNames}
          placeholder={"Customer Name"}
          onChange={(option: any) =>
            onChangeUpdateState({ customerName: option.value })
          }
        />
        <div className="flex">
          <input
            type="number"
            value={advancedFilterOptions.hireDuration}
            className="relative w-1/2 bg-white border border-gray-300 rounded-md shadow-sm mr-2 pl-3 pr-3 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Hire Duration (days)"
            onChange={e => {
              onChangeUpdateState({ hireDuration: e.target.value })
            }}
          />
          <Select
            className="w-1/2 ml-2"
            value={
              advancedFilterOptions.isQuote === ""
                ? null
                : convertTypeDataToSelectType(advancedFilterOptions.isQuote)
            }
            options={contractTypes}
            placeholder="Type"
            onChange={(option: any) => {
              onChangeUpdateState({ isQuote: option.value })
            }}
          />
        </div>
        <Select
          value={
            advancedFilterOptions.assignedUser === ""
              ? null
              : convertDataToSelectType(advancedFilterOptions.assignedUser)
          }
          escapeClearsValue={true}
          backspaceRemovesValue={true}
          options={props.assignedUsers}
          placeholder={"Assigned User"}
          onChange={(option: any) =>
            onChangeUpdateState({ assignedUser: option.value })
          }
        />
        <div className="relative">
          <DatePicker
            dateFormat="dd-MM-yyyy"
            selected={
              advancedFilterOptions.requiredDateFrom === ""
                ? null
                : new Date(advancedFilterOptions.requiredDateFrom)
            }
            onChange={(dates: any) => {
              const [start, end] = dates
              const startStr =
                start != null ? moment(start).format("YYYY-MM-DD") : ""
              const endStr = end != null ? moment(end).format("YYYY-MM-DD") : ""
              onChangeUpdateState({
                requiredDateFrom: startStr,
                requiredDateTo: endStr,
              })
            }}
            startDate={
              advancedFilterOptions.requiredDateFrom === ""
                ? null
                : new Date(advancedFilterOptions.requiredDateFrom)
            }
            endDate={
              advancedFilterOptions.requiredDateTo === ""
                ? null
                : new Date(advancedFilterOptions.requiredDateTo)
            }
            selectsRange
            showDisabledMonthNavigation
            placeholderText="Date - Range"
            className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22.065}
            height={24.406}
            className="absolute top-[7px] right-[15px]"
          >
            <defs>
              <style>
                {
                  ".a{fill:none;stroke:#4c4a4a;stroke-linecap:round;stroke-linejoin:round}"
                }
              </style>
            </defs>
            <path
              className="a"
              d="M2.841 2.953h16.383a2.334 2.334 0 0 1 2.341 2.328v16.3a2.334 2.334 0 0 1-2.341 2.328H2.841A2.334 2.334 0 0 1 .5 21.578V5.281a2.334 2.334 0 0 1 2.341-2.328ZM15.714.5v4.906M6.351.5v4.906M.5 9.862h21.065"
            />
          </svg>
        </div>
      </div>
      <div className="flex mt-4 items-center">
        <button
          type="button"
          className="self-start px-4 py-2 mr-3 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-horizonred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={search}
        >
          {getResourceStringByid(
            resourceStrings,
            "b7f65657-3ba8-57ab-9dfd-cecf693058eb"
          )}
        </button>
        <button
          className="text-xs underline"
          type="button"
          onClick={() => {
            onChangeUpdateState(baseFilters)
            props.updateFilterState(baseFilters)
          }}
        >
          {getResourceStringByid(
            resourceStrings,
            "70441d4c-bea0-5fe2-b19b-08eccc05c4ef"
          )}
        </button>
      </div>
    </form>
  )
}
