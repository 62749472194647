import React from "react"
import { ITotalType } from "../../types/interfaces/IGetAllContractsResponse"
import baseFilters from "../../mocks/baseFilters.json"

export type cardTotalProps = {
  totals: ITotalType
  title: string
  updateFilterState: (state: any, fromCardTotal: boolean) => void
}

export const CardTotal = (props: cardTotalProps) => {
  return (
    <li className="flex flex-col flex-grow col-span-4 overflow-y-auto text-center bg-white border rounded-lg">
      <div className="flex flex-col">
        <button
          onClick={() =>
            props.updateFilterState({
              ...baseFilters,
              ...{ hireStartDateFromNow: props.totals.other.name },
            },
            true)
          }
        >
          <div className="py-4 border-b">
            <h3 className="text-xl font-bold tracking-tight text-gray-900 uppercase">
              {props.title}
            </h3>
            <span className="text-5xl font-extrabold text-horizonred">
              {props.totals.total}
            </span>
          </div>
        </button>
      </div>
      <div className={`${props.totals.total > 0 ? "h-full " : ""} flex flex-row`}>
        <div className="flex flex-col grow border-r max-w-[50%]">
          <button
            onClick={() =>
              props.updateFilterState({
                ...baseFilters,
                ...{ hireStartDateFromNow: props.totals.other.name },
                isQuote: "N",
              }, 
              true)
            }
          >
            <div className="py-2 border-b">
              <h3 className="font-bold tracking-tight text-gray-900 uppercase">
                Firm Bookings
              </h3>
              <span className="text-3xl font-extrabold text-horizonred">
                {props.totals.other.total}
              </span>
            </div>
          </button>
          {props.totals.other.locations.length > 0 && (
            <>
              <p className="px-4 mt-3 font-bold text-left uppercase">Locations</p>
              <ul role="list" className="max-h-44 overflow-y-auto mb-2">
                {props.totals.other.locations.map((location, index) => {
                  return (
                    <li key={index}>
                      <button
                        type="button"
                        onClick={() =>
                          props.updateFilterState({
                            ...baseFilters,
                            ...{
                              location: location.name,
                              hireStartDateFromNow: props.totals.other.name,
                              isQuote: "N",
                            }
                          },
                          true)
                        }
                        className="block w-full rounded-lg hover:bg-gray-100 odd:bg-white even:bg-gray-50"
                      >
                        <div className="px-4 py-2">
                          <div className="flex items-center justify-between">
                            <p className="text-sm font-medium text-black truncate">
                              {location.name}
                            </p>
                            <div className="flex flex-shrink-0 ml-2">
                              <p className="inline-flex px-2 text-xs font-semibold leading-5 rounded-full text-horizonred">
                                {location.total}
                              </p>
                            </div>
                          </div>
                        </div>
                      </button>
                    </li>
                  )
                })}
              </ul>
            </>
          )}
        </div>
        <div className="flex flex-col grow max-w-[50%]">
          <button
              onClick={() =>
                props.updateFilterState({
                  ...baseFilters,
                  ...{ hireStartDateFromNow: props.totals.other.name },
                  isQuote: "Y",
                },
                true)
              }
            >
            <div className="py-2 border-b">
              <h3 className="font-bold tracking-tight text-gray-900 uppercase">
                Quotes
              </h3>
              <span className="text-3xl font-extrabold text-horizonred">
                {props.totals.quotes.total}
              </span>
            </div>
          </button>
          {props.totals.quotes.locations.length > 0 && (
            <>
              <p className="px-4 mt-3 font-bold text-left uppercase">Locations</p>
              <ul role="list" className="max-h-44 overflow-y-auto mb-2">
                {props.totals.quotes.locations.map((location, index) => {
                  return (
                    <li key={index}>
                      <button
                        type="button"
                        onClick={() =>
                          props.updateFilterState({
                            ...baseFilters,
                            ...{
                              location: location.name,
                              hireStartDateFromNow: props.totals.quotes.name,
                              isQuote: "Y",
                            },
                          },
                          true)
                        }
                        className="block w-full rounded-lg hover:bg-gray-100 odd:bg-white even:bg-gray-50"
                      >
                        <div className="px-4 py-2">
                          <div className="flex items-center justify-between">
                            <p className="text-sm font-medium text-black truncate">
                              {location.name}
                            </p>
                            <div className="flex flex-shrink-0 ml-2">
                              <p className="inline-flex px-2 text-xs font-semibold leading-5 rounded-full text-horizonred">
                                {location.total}
                              </p>
                            </div>
                          </div>
                        </div>
                      </button>
                    </li>
                  )
                })}
              </ul>
            </>
          )}
        </div>
      </div>
    </li>
  )
}
